.it-footer {
  .it-footer-main {
    background-color: $footer-main-bg-color;

    .it-brand-wrapper {
      a {
        .icon {
          width: auto;
        }
      }
    }
  }
  .it-footer-small-prints {
    background-color: $footer-small-prints-bg-color;
    a,
    button {
      color: $primary-dark;
      &:hover {
        color: tint-color($primary-dark, 30%);
      }
    }
  }
}
