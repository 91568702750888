//site
$primary-h: 39 !default;
$primary-s: 66 !default;
$primary-b: 15 !default;
$primary: #27420f;

//$secondary;
//$tertiary: #ed1c25;
//$tertiary-text: $white;
$light-gray-bg: #edebec;

$primary-dark: #2e2e2e;
$header-slim-bg-color: $light-gray-bg;
$header-slim-text-color: $primary-dark;

$footer-main-bg-color: $primary-dark;
$footer-small-prints-bg-color: $light-gray-bg;
